import React from "react";
import styled, { ThemeProvider } from "styled-components";
import Carousel from "../Carousel";
import Button from "../Button";
import { dark } from "../../styles/Themes";

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Container = styled.div`
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;

const Box = styled.div`
  width: 50%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {
    min-height: 50vh;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  line-height: 1.2;
  margin-bottom: 0.5rem;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  line-height: 1.2;
  margin: 1rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
    margin: 1rem auto;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    width: 85%;
  }
`;

const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: #b8d1e7;
  align-self: flex-start;
  line-height: 1.2;
  margin: 1rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
    margin: 1rem auto;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
    width: 85%;
  }
`;

const ButtonContainer = styled.div`
  margin: 1rem 0;
  align-self: flex-start !important;
  display: flex;
  margin-bottom: 2rem;

  @media (max-width: 64em) {
    width: 100%;

    button {
      margin: 0 auto;
    }
  }
`;

const About = () => {
  return (
    <Section id="about">
      <Container>
        <Box>
          <Carousel />
        </Box>
        <Box>
          <SubText>
            <span style={{ textDecoration: "underline" }}>FBCharts</span> is a browser extension that auto-creates Football Radar Charts on FBref.com.
          </SubText>
          <SubTextLight>
            ⭐️ Data Accuracy ⭐️<br /><br />Using FBref.com (powered by Opta), the data behind the charts are always up-to-date and accurate. <br /><br />
            🌍 Gargantuan Database 🌍<br /><br />Supports 28 competitions, including: <br /><br />
            <ul style={{ marginLeft: "10px", lineHeight: "1.5" }}>
              - Women's Top 9 Competitions<br />
              - Men's Big 5 Leagues, UCL, UEL<br />
              - Men's Next 14 Competitions: 
                <ul style={{ marginLeft: "10px", lineHeight: "1.5", marginTop: "0px" }}>
                  - American Major League Soccer<br />
                  - Brazilian Série A<br />
                  - Dutch Eredivisie<br />
                  - English Championship<br />
                  - French Ligue 2<br />
                  - German 2.Bundesliga<br />
                  - Italian Serie B<br />
                  - Mexican Liga MX<br />
                  - Portuguese Primeira Liga<br />
                  - Spanish Segunda<br />
                  and more
                </ul>
            </ul>
          </SubTextLight>
          {/* <ButtonContainer>
            <ThemeProvider theme={dark}>
              <Button text="JOIN OUR DISCORD" link="#" />
            </ThemeProvider>
          </ButtonContainer> */}
        </Box>
      </Container>
    </Section>
  );
};

export default About;
