import * as React from 'react';
const LogoSvg = props => (
  <svg
    width={485.98334655761715}
    height={78.90358120926227}
    viewBox="0 0 369.58424507658646 60.00518474887082"
    className="css-1j8o68f"
    {...props}
  >
    <defs id="SvgjsDefs6287" />
    <g
      id="SvgjsG6288"
      featurekey="symbolFeature-0"
      transform="matrix(1.340262582056893,0,0,1.340262582056893,0,-10.062363238512036)"
      fill="#5b5b5b"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M59,9H1c-0.553,0-1,0.448-1,1v40c0,0.552,0.447,1,1,1h58c0.553,0,1-0.448,1-1V10C60,9.448,59.553,9,59,9z M31,23.08    c3.387,0.488,6,3.4,6,6.92c0,3.52-2.613,6.432-6,6.92V23.08z M29,36.92c-3.387-0.488-6-3.4-6-6.92c0-3.52,2.613-6.432,6-6.92    V36.92z M29,21.059c-4.493,0.5-8,4.317-8,8.941s3.507,8.441,8,8.941V49H2V11h27V21.059z M58,49H31V38.941    c4.493-0.5,8-4.317,8-8.941s-3.507-8.441-8-8.941V11h27V49z" />
        </g>
      </g>
    </g>
    <g
      id="SvgjsG6289"
      featurekey="nameFeature-0"
      transform="matrix(1.8294261940824736,0,0,1.8294261940824736,96.34114848417344,-13.903638028220735)"
      fill="#5b5b5b"
    >
      <path d="M2 40 l0 -32 l8.4 0 l0 3.2 l-4.4 0 l0 12 l3.6 0 l0 3.2 l-3.6 0 l0 13.6 l-4 0 z M18.200000000000003 40 l0 -32 l6.08 0 c4.12 0 5.48 2.56 5.48 7.16 l0 0.84 c0 2.76 -0.64 4.92 -1.36 6.48 l0 0.16 c1.48 1.24 2.4 3.68 2.6 7.92 l0 1.28 c-0.32 5.64 -1.8 8.16 -5.92 8.16 l-6.88 0 z M22.200000000000003 21.6 l2.08 0 c1.12 0 1.92 -1.32 1.92 -5.6 l0 -0.84 c0 -3.08 -0.72 -3.96 -1.92 -3.96 l-2.08 0 l0 10.4 z M22.200000000000003 36 l2.88 0 c1.2 0 2.04 -1 2.2 -4.16 l0 -1.28 c-0.16 -4.76 -1 -5.76 -2.2 -5.76 l-2.88 0 l0 11.2 z M38.400000000000006 23.16 c0 -11.16 0 -15.56 5.6 -15.56 c4.12 0 5.24 2.52 5.52 8.56 l-3.72 0 c-0.16 -4.36 -0.6 -5.36 -1.8 -5.36 c-1.84 0 -1.84 1.96 -1.84 12.36 c0 11.56 0 13.24 1.84 13.24 c1.32 0 1.72 -1 1.8 -6.16 l3.76 0 c-0.24 7.4 -1.24 10.16 -5.56 10.16 c-5.6 0 -5.6 -4.44 -5.6 -17.24 z M57.36000000000001 40 l0 -32 l4 0 l0 13.6 l4 0 l0 -13.6 l4 0 l0 32 l-4 0 l0 -15.2 l-4 0 l0 15.2 l-4 0 z M78.36000000000001 40 l0 -16.8 c0 -11.16 0 -15.56 6 -15.56 s6 4.4 6 15.56 l0 16.8 l-4 0 l0 -9.2 l-4 0 l0 9.2 l-4 0 z M82.36000000000001 27.6 l4 0 l0 -4.4 c0 -10.4 0 -12.36 -2 -12.36 s-2 1.96 -2 12.36 l0 4.4 z M99.36000000000001 40 l0 -32 l6.48 0 c4.12 0 5.52 2.52 5.52 7.36 c0 4.36 -0.52 6.84 -1.76 8.04 l0 0.24 c1.04 0.36 1.36 2.24 1.76 6.92 l0.8 9.44 l-4 0 l-0.52 -9.44 c-0.28 -4.76 -1 -5.76 -2.2 -5.76 l-2.08 0 l0 15.2 l-4 0 z M103.36000000000001 21.6 l2.48 0 c1.4 0 1.8 -1.4 1.8 -6.24 c0 -3.16 -0.6 -4.16 -1.8 -4.16 l-2.48 0 l0 10.4 z M118.76000000000002 11.600000000000001 l0 -3.6 l12 0 l0 3.6 l-4 0 l0 28.4 l-4 0 l0 -28.4 l-4 0 z M137.36 30.240000000000002 l4.04 0 c0.08 5.16 0.48 6.16 1.92 6.16 c1.96 0 1.96 -1.64 1.96 -6 c0 -6.4 -7.92 -4.68 -7.92 -13.2 c0 -5 0 -9.6 6 -9.6 c4.44 0 5.64 2.52 5.92 8.56 l-4 0 c-0.16 -4.36 -0.64 -5.36 -1.92 -5.36 c-1.96 0 -1.96 2.16 -1.96 6.4 c0 6.2 7.96 4 7.96 13.2 c0 5.6 0 10 -6.04 10 c-4.64 0 -5.72 -2.76 -5.96 -10.16 z" />
    </g>
  </svg>
);
export default LogoSvg;
