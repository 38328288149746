import React from 'react';
import styled from 'styled-components';
import * as marked from 'marked';
import { Helmet } from 'react-helmet';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
`;

const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
`;

const MarkdownContent = styled.div`
  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 10px;
    padding-left: 20px;
  }

  a {
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  em {
    font-style: italic;
  }
`;

const TermsAndConditions = () => {
	const content = `
## 1. Description of the Service
FBCharts is a browser extension that translates statistics provided by fbref.com into visual charts, including radar charts and comparison charts. The service enhances the user experience by offering an intuitive and visually engaging way to analyse football statistics.

## 2. User Responsibilities
By using FBCharts, you agree to the following:

### User Behaviour
- You must not engage in any malicious activities, including attempts to disrupt the service on FBCharts. This policy helps maintain consistently high-quality service for all users.
  
### Account Usage
- You must not share your account with any other individual or entity. Please contact us if you need multi-individual access.
  
### Subscription Access
- You shall not share, transfer, sell, or provide your subscription access or the charts created by the extension to any third party without written consent.

## 3. Subscription and Payment Terms
**Subscription Cost:** The premium subscription costs a standard rate of £3 per month.  
**Payment Methods:** We accept payment types accepted by Stripe.  
**Cancellation Policy:** You have the right to cancel your premium subscription at any time. The cancellation will take effect at the end of the current billing cycle: you will retain access to the platform until the end of the current paid period, and you will not be billed for the next cycle. Refunds for cancellations will not be provided for any remaining unused days in the billing cycle.  
**Cancellation Instructions:** To cancel your subscription, please log in to FBCharts while on fbref.com, click "premium" (bottom of the interface, next to "usage tips"), and follow the instructions on the pop-up to cancel your subscription.  
**Refund Policy:** All sales are final.  
**Non-Payment:** If the monthly subscription fee is not paid, access to premium features could be disrupted until the payment is made. Users will retain access to basic features available to free users.

## 4. Liability and Disclaimers
**Limitation of Liability:** To the maximum extent permitted by law, FBCharts shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use FBCharts.

## 5. Termination of Service
**Termination Conditions:** We reserve the right to terminate your access to FBCharts without notice if you violate these Terms and Conditions or engage in prohibited behaviours.  
**Refunds on Termination:** Users will not receive a refund if their access is terminated.

## 6. Performance Dependency
**Dependency on fbref.com:** FBCharts operates as a browser extension/add-on dependent on the performance and functionality of fbref.com. Changes or downtime affecting fbref.com may impact the functionality of FBCharts. Users acknowledge and understand that such occurrences are possible, and we are committed to promptly addressing any issues related to fbref.com to minimize disruption to our users’ experience.

In the unlikely event that FBCharts can no longer operate due to circumstances beyond our control, such as the permanent closure or substantial changes to fbref.com, subscriptions may be cancelled on behalf of our users to ensure fairness.

## 7. Privacy Policy

User Information: The only personal information collected is the email address used for premium login, which is also used as the user ID on the platform to identify custom user charts. No other personal information is needed or collected.

## 8. Governing Law
These Terms and Conditions shall be governed by and construed in accordance with the laws of Canada.

## 9. Dispute Resolution
**Dispute Resolution Method:** Disputes arising from these Terms and Conditions will first be addressed through informal negotiations. If unresolved, disputes will be settled by arbitration in Canada.

## 10. Contact Us
If you have any questions or concerns about these Terms and Conditions or FBCharts, please contact us via [chartsfb@gmail.com](mailto:chartsfb@gmail.com).

<em>
Acknowledgment

By using FBCharts, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. These terms constitute the entire agreement between you and FBCharts regarding your use of the service.
</em>
`;

	// Convert Markdown content to HTML using marked
	const htmlContent = marked.parse(content);

	return (
		<Container>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<Title>FBCharts Terms and Conditions</Title>
			<MarkdownContent dangerouslySetInnerHTML={{ __html: htmlContent }} />
		</Container>
	);
};

export default TermsAndConditions;
