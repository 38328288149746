import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, EffectCards, Autoplay } from "swiper";

import img1 from "../assets/charts/7.png";
import img2 from "../assets/charts/8.png";
import img3 from "../assets/charts/9.png";
import img4 from "../assets/charts/10.png";
import img5 from "../assets/charts/11.png";
import img6 from "../assets/charts/12.png";

// import Arrow from "../assets/Arrow.svg";

const Container = styled.div`
  width: 42vh;
  height: 50vh;

  @media (max-width: 1265px) {
    width: 42vh;
    height: 50vh;
  }

  @media (max-width: 1120px) {
    width: 35vh;
    height: 40vh;
  }

  @media (max-width: 1024px) {
    width: 42vh;
    height: 50vh;
  }

  @media (max-width: 640px) {
    width: 35vh;
    height: 40vh;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 42vh;
      height: 50vh;
    }

    @media (max-width: 1120px) {
      img {
        width: 35vh;
        height: 40vh;
      }
    }

    @media (max-width: 1024px) {
      img {
        width: 42vh;
        height: 50vh;
      }
    }

    @media (max-width: 640px) {
      img {
        width: 35vh;
        height: 40vh;
      }
    }
  }
`;

const Carousel = () => {
  return (
    <Container>
      <Swiper
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          type: "fraction",
        }}
        scrollbar={{
          draggable: true,
        }}
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards, Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img2} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img3} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img4} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img5} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img6} />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default Carousel;
