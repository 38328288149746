import React from "react";
import styled from "styled-components";

import img1 from "../assets/charts/1.png";
import img2 from "../assets/charts/2.png";
import img3 from "../assets/charts/3.png";
import img4 from "../assets/charts/4.png";
import img5 from "../assets/charts/5.png";
import img6 from "../assets/charts/6.png";

const Section = styled.section`
  width: 100vw;
  height: 25rem;
  position: relative;
  border-top: 2px solid ${(props) => props.theme.text};
  border-bottom: 2px solid ${(props) => props.theme.text};
  background-color: ${(props) => `rgba(${props.theme.textRgba}, 0.9)`};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 48em) {
    height: 15rem;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;

  img {
    width: 15rem;
    height: auto;
  }

  @media (max-width: 48em) {
    img {
      width: 10rem;
    }
  }
`;

const Title = styled.h1`
  margin-bottom: -30px;
  font-size: ${(props) => props.theme.fontxxl};
  color: ${(props) => props.theme.body};
  padding: 1rem 2rem;
  z-index: 10;
  width: 30%;
  text-transform: capitalize;
  text-shadow: 1px 1px 2px ${(props) => props.theme.text};

  @media (max-width: 64em) {
    width: 40%;
    font-size: ${(props) => props.theme.fontxxl};
    text-align: center;
  }

  @media (max-width: 48em) {
    width: 100%;
    padding: 2rem 0;
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const BtnContainer = styled.div`
  z-index: 999;
  width: 35%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 48em) {
    width: 100%;
    justify-content: center;
  }
`;

const DownloadContainer = styled.div`
  display: block;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const DownloadButton = styled.a`
  cursor: pointer;

  display: block;
  width: max-content;
  margin: 16px auto;
  padding: 16px;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
`;

const ChromeButton = styled(DownloadButton)`
  background-color: #22a699;

  &:hover {
    background-color: #00c3b0;
    text-decoration: none;
    color: #ffffff;
  }
`;

const FirefoxButton = styled(DownloadButton)`
  background-color: #ff7a25;

  &:hover {
    background-color: #ff9959;
    text-decoration: none;
    color: #ffffff;
  }
`;

const Banner = () => {
  return (
    <Section>
      <ImageContainer>
        <img src={img1} alt="example1" />
        <img src={img2} alt="example2" />
        <img src={img3} alt="example3" />
        <img src={img4} alt="example4" />
        <img src={img5} alt="example5" />
        <img src={img6} alt="example6" />
      </ImageContainer>

      <Title>
        Start creating your own
      </Title>
      <BtnContainer>
        <DownloadContainer>
          <ChromeButton
            href="https://chrome.google.com/webstore/detail/fbcharts/ogkjijheeoanpdacakgofneeagpakbnb"
            target="_blank"
            rel="noopener"
          >
            <span>Download for Chrome / Edge</span>
          </ChromeButton>
          <FirefoxButton
            href="https://addons.mozilla.org/firefox/addon/fbcharts"
            target="_blank"
            rel="noopener"
          >
            <span>Download for Firefox</span>
          </FirefoxButton>
        </DownloadContainer>
      </BtnContainer>
    </Section>
  );
};

export default Banner;
