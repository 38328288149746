import React from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import Button from "./Button";

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  width: 80%;
  color: ${(props) => props.theme.text};
  align-self: flex-start;
  line-height: 1.2;
  margin-bottom: 0.5rem;

  span {
    font-size: 28px;
    text-transform: uppercase;
  }

  .text-1 {
    color: #346ba2;
  }

  .text-2 {
    color: #F24C3D;
  }

  .text-3 {
    color: blue;
  }

  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }

  @media (max-width: 40em) {
    width: 90%;
  }
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba}, 0.6)`};
  // font-weight: 600;
  margin-bottom: 1rem;
  width: 80%;
  align-self: flex-start;

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const ButtonContainer = styled.div`
  width: 320px;
  align-self: flex-start;
  display: flex;
  justify-content: center;

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }
`;

const DownloadContainer = styled.div`
  display: block;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 30px;
`;

const DownloadButton = styled.a`
  cursor: pointer;

  display: block;
  width: max-content;
  margin: 20px auto;
  padding: 20px;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 20px;
  text-decoration: none;
`;

const ChromeButton = styled(DownloadButton)`
  background-color: #22a699;

  &:hover {
    background-color: #00c3b0;
    text-decoration: none;
    color: #ffffff;
  }
`;

const FirefoxButton = styled(DownloadButton)`
  background-color: #ff7a25;

  &:hover {
    background-color: #ff9959;
    text-decoration: none;
    color: #ffffff;
  }
`;

const TypeWriterText = () => {
  return (
    <>
      <Title>
        Create your own Football
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString('<span class="text-1">Radar Charts.</span>')
              .pauseFor(1500)
              .deleteAll()
              .typeString('<span class="text-2">Comparison Charts.</span>')
              .pauseFor(1500)
              .deleteAll()
              .start();
          }}
        />
      </Title>
      <SubTitle>Super easy.</SubTitle>
      <DownloadContainer>
        <ChromeButton
          href="https://chrome.google.com/webstore/detail/fbcharts/ogkjijheeoanpdacakgofneeagpakbnb"
          target="_blank"
          rel="noopener"
        >
          <span>Download for Chrome / Edge</span>
        </ChromeButton>
        <FirefoxButton
          href="https://addons.mozilla.org/firefox/addon/fbcharts"
          target="_blank"
          rel="noopener"
        >
          <span>Download for Firefox</span>
        </FirefoxButton>
      </DownloadContainer>
    </>
  );
};

export default TypeWriterText;
